<template>
  <v-navigation-drawer stateless temporary fixed width="40vw" color="drawerBackground" v-model="show" right>
    <v-card tile v-if="currentOrganization" :light="!darkMode" :dark="darkMode" style="height: 100vh; width: 40vw">
      <v-card-title class="pl-4">
        <v-row no-gutters>
          <h3>{{ isEditing ? 'Rediger profil' : 'Opret køreskole' }}</h3>
          <r-btn class="ml-auto" icon @click="close">
            <v-icon dark>mdi-close-thick</v-icon>
          </r-btn>
        </v-row>
      </v-card-title>
      <v-card-text class="pl-6">
        <v-form v-model="isValid" ref="editOrganizationRef">
          <v-row class="mb-4" no-gutters>
            <v-col cols="12" md="3" align-self="center">Navn:*</v-col>
            <v-col>
              <v-text-field v-model="currentOrganization.name" hide-details="auto" outlined :rules="[rules.required]" dense></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mb-4" no-gutters>
            <v-col cols="12" md="3" align-self="center">Email:</v-col>
            <v-col>
              <v-text-field v-model="currentOrganization.email" outlined hide-details="auto" dense :rules="[rules.required]"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mb-4" no-gutters>
            <v-col cols="12" md="3" align-self="center">CVR:</v-col>
            <v-col>
              <v-text-field v-model="currentOrganization.cvr" outlined hide-details="auto" dense></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mb-4" no-gutters>
            <v-col cols="12" md="3" align-self="center">Tlf. Nummer:*</v-col>
            <v-col>
              <v-text-field v-model="currentOrganization.phone" outlined hide-details="auto" :rules="[rules.required]" dense></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mb-4" no-gutters>
            <v-col cols="12" md="3" align-self="center">Adresse:</v-col>
            <v-col>
              <v-text-field v-model="currentOrganization.address" outlined hide-details="auto" dense></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mb-4" no-gutters>
            <v-col cols="12" md="3" align-self="center">Tilad glatbanebooking:</v-col>
            <v-col>
              <v-checkbox v-model="currentOrganization.allowTechnicalTrackBooking" hide-details="auto"></v-checkbox>
            </v-col>
          </v-row>
          <div v-if="!isEditing">
            <v-row class="mb-4" no-gutters>
              <v-col cols="12" md="3" align-self="center">Kodeord:</v-col>
              <v-col>
                <v-text-field v-model="currentOrganization.password" outlined hide-details="auto" dense></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mb-4" no-gutters>
              <v-col>Hvis kodeord er blankt bliver der lavet et tilfældigt kodeord til køreskolens bruger og de vil få en velkomstmail med hjælp til at logge ind første gang og ændre deres kodeord.</v-col>
            </v-row>
            <v-row class="mb-4" no-gutters>
              <v-col cols="12" md="3" align-self="center">Lektionsplaner:</v-col>
              <v-col>
                <v-select
                  multiple
                  :items="lessonPlans"
                  v-model="currentOrganization.lessonPlans"
                  item-value="id"
                  item-text="name"
                  outlined
                  dense
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row class="mb-4" no-gutters>
              <v-col>Vælg en eller flere af dine lektionsplaner der skal kopieres til den nye køreskole.</v-col>
            </v-row>
          </div>
        </v-form>
        <p class="text-caption">Felter med asterisk (*) er obligatoriske.</p>
      </v-card-text>
      <v-card-actions>
        <v-row class="pl-4" no-gutters>
          <r-btn :block="$vuetify.breakpoint.mobile" class="mr-lg-4 mb-2" @click="show = false">Annuller</r-btn>
          <r-btn :block="$vuetify.breakpoint.mobile" creation :loading="isSaving" :disabled="!isValid" @click="saveCurrentOrganization">
            <v-icon left>mdi-check</v-icon>
            {{ isEditing ? 'Gem ændringer' : 'Opret' }}
          </r-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import _ from 'lodash';
import apiService from '@/services/apiService';
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    show: false,
    currentOrganization: {},
    lessonPlans: [],
    isSaving: false,
    isValid: false,
    rules: {
      required: value => !!value || 'Skal indeholde tekst',
    },
  }),
  computed: {
    ...mapGetters(['isAdmin', 'user', 'darkMode', 'currentOrgId']),
    isEditing() {
      return this.currentOrganization?.id ? true : false;
    },
  },
  methods: {
    editOrganization(Organization) {
      this.isSaving = false;
      this.currentOrganization = _.cloneDeep(Organization);
      this.show = true;
    },
    async createOrganization() {
      this.isSaving = false;
      this.currentOrganization = {
        name: '',
        email: '',
        cvr: '',
        phone: '',
        address: '',
        country: '',
        password: null,
        lessonPlans: [],
        allowTechnicalTrackBooking: false,
      };

      this.lessonPlans = await apiService.getModulePlanList(this.currentOrgId, false);
      this.show = true;
    },
    async saveCurrentOrganization() {
      this.isSaving = true;
      await apiService
        .updateOrganization(this.currentOrganization)
        .then(() => {
          this.show = false;
          this.$emit('organization-updated');
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
    close() {
      this.$emit('organization-updated');
      this.show = false;
    },
  },
};
</script>

<style>
</style>
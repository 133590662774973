<template>
  <v-container fluid>
    <v-sheet class="d-flex mx-auto py-4" min-height="80vh" :width="$vuetify.breakpoint.mobile ? '92vw' : '80vw'">
      <v-row no-gutters>
        <v-col cols="12" md="3" class="px-2 py-4">
          <v-row no-gutters justify="center">
            <v-col cols="12" class="text-center">
              <v-icon size="125" color="activeButton"> mdi-town-hall </v-icon>
            </v-col>
            <v-col cols="12">
              <p class="text-h5 mb-0 text--secondary text-center font-weight-medium">{{ currentOrganization.name }}</p>
            </v-col>
            <v-col cols="12"> </v-col>
          </v-row>
          <div class="d-block mt-8 px-4">
            <r-btn v-if="isAdmin || (orgAccess != null && orgAccess.includes('ModifySite'))" class="mb-3" @click="editOrganization" block>
              <v-icon left>mdi-pencil</v-icon>
              Rediger profil
            </r-btn>
            <!-- <r-btn
              v-if="isAdmin || (currentOrgId == currentOrganization.id && orgAccess != null && orgAccess.includes('ModifyStaff'))"
              class="mb-3"
              block
              @click="addStaff"
            >
              Tilføj kørelærer
            </r-btn> -->
            <r-btn v-if="orgAccess != null && orgAccess.includes('ModifyStudents')" block class="mb-3" @click="addStudent">Tilføj elev</r-btn>
            <r-btn v-if="(orgAccess != null && orgAccess.includes('ModifyStudents')) || isAdmin" class="mb-3" @click="createCode" block>
              <v-icon left>mdi-plus</v-icon>
              Opret elev kode
            </r-btn>
            <r-btn class="mb-3" block @click="deleteOrganization" v-if="(orgAccess != null && orgAccess.includes('ModifySite')) || isAdmin" delete>
              <v-icon left>mdi-cancel</v-icon>
              Slet
            </r-btn>
          </div>
        </v-col>
        <v-divider vertical inset />
        <v-col cols="12" md="4" class="px-2 py-4 text-subtitle-1">
          <v-row no-gutters class="text-left px-4"> <p class="text-h6 text--secondary font-weight-medium my-auto">Detaljer</p> </v-row>
          <div class="px-4">
            <v-row class="px-2 my-1">
              <v-col>Navn:</v-col>
              <v-col cols="auto">{{ currentOrganization.name }}</v-col>
            </v-row>
            <v-divider />
            <v-row class="px-2 my-1">
              <v-col>Email:</v-col>
              <v-col cols="auto">{{ currentOrganization.email }}</v-col>
            </v-row>
            <v-divider />
            <v-row class="px-2 my-1">
              <v-col>Adresse:</v-col>
              <v-col cols="auto">{{ currentOrganization.address }}</v-col>
            </v-row>
            <v-divider />
            <v-row class="px-2 my-1">
              <v-col>Telefon nummer:</v-col>
              <v-col cols="auto">{{ currentOrganization.phone }}</v-col>
            </v-row>
            <v-divider />
            <v-row class="px-2 my-1">
              <v-col>CVR:</v-col>
              <v-col cols="auto">{{ currentOrganization.cvr }}</v-col>
            </v-row>
            <v-divider />
            <v-row class="px-2 my-1">
              <v-col>Tillad Glatbanebooking:</v-col>
              <v-col cols="auto"><readonly-checkbox :value="currentOrganization.allowTechnicalTrackBooking" /></v-col>
            </v-row>
            <!-- <v-divider v-if="currentOrganization.theoryTeamId" /> -->
          </div>
        </v-col>
        <v-divider vertical inset />
        <v-col v-if="currentOrganization.staff || currentOrganization.students" cols="12" md="5" class="px-2 py-4">
          <div class="d-flex flex-column" style="height: 74vh">
            <div class="d-flex px-4">
              <v-tabs fixed-tabs class="mb-3" v-model="currentTab">
                <v-tab :key="0" class="text-none text-h6 text-secondary" v-if="isAdmin">Kørelærere</v-tab>
                <v-tab :key="1" class="text-none text-h6 text-secondary" v-if="isAdmin || (orgAccess != null && orgAccess.includes('ReadStudents'))"
                  >Elever</v-tab
                >
                <v-tab :key="2" class="text-none text-h6 text-secondary" v-if="isAdmin || (orgAccess != null && orgAccess.includes('ModifyStudents'))"
                  >Koder</v-tab
                >
              </v-tabs>
            </div>
            <div style="overflow-y: auto">
              <v-tabs-items v-model="currentTab">
                <v-tab-item :key="0" v-if="isAdmin">
                  <div v-for="(item, index) in currentOrganization.staff" :key="index" class="mb-4 mt-1 px-4">
                    <v-card elevation="1">
                      <v-row no-gutters>
                        <v-col class="px-3 py-2">
                          <p class="text-h6 mb-2">{{ item.name }}</p>
                          <p class="text-subtitle-1">{{ item.email }}</p>
                          <p class="text-subtitle-1">{{ item.phoneNumber }}</p>
                        </v-col>
                        <v-col cols="auto" class="d-flex align-center mr-2">
                          <r-btn color="Activebutton" x-large icon @click="userDetails(item)" title="Detaljer">
                            <v-icon>mdi-dots-horizontal</v-icon>
                          </r-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                  </div>
                </v-tab-item>
                <v-tab-item :key="1" v-if="isAdmin || (orgAccess != null && orgAccess.includes('ReadStudents'))">
                  <div v-for="(item, index) in currentOrganization.students" :key="index" class="mb-4 mt-1 px-4">
                    <v-card elevation="2">
                      <v-row no-gutters>
                        <v-col class="px-3 py-2">
                          <p class="text-h6 mb-2">{{ item.name }}</p>
                          <p class="text-subtitle-1">{{ item.email }}</p>
                          <p class="text-subtitle-1">{{ item.phoneNumber }}</p>
                        </v-col>
                        <v-col cols="auto" class="d-flex align-center mr-2">
                          <r-btn color="Activebutton" x-large icon @click="userDetails(item)" title="Detaljer">
                            <v-icon>mdi-dots-horizontal</v-icon>
                          </r-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                  </div>
                </v-tab-item>
                <v-tab-item :key="2" v-if="isAdmin || (orgAccess != null && orgAccess.includes('ModifyStudents'))">
                  <div v-for="(item, index) in codes" :key="index" class="mb-4 mt-1 px-4">
                    <v-card elevation="2">
                      <v-row no-gutters>
                        <v-col cols="12" class="px-3 py-2">
                          <p class="text-h6 mb-2">Kode: {{ item.code }}</p>
                          <p class="text-subtitle-1">Udløber {{ item.expiryDate }}<br />{{ item.moduleListName }}</p>
                        </v-col>
                      </v-row>
                    </v-card>
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-sheet>
    <confirmation-dialog ref="confirmationDialogRef" />
    <create-edit-user ref="CreateEditUserRef" @user-updated="getOrganizationDetails" />
    <create-edit-organization ref="CreateEditOrganizationRef" @organization-updated="getOrganizationDetails" />
    <add-code @code-result="handleCode" ref="AddCodeRef"></add-code>
  </v-container>
</template>

<script>
import apiService from '@/services/apiService';
import CreateEditUser from '@/components/dialogs/CreateEditUser.vue';
import CreateEditOrganization from '@/components/dialogs/CreateEditOrganization.vue';
import ReadonlyCheckbox from '@/components/ReadonlyCheckbox.vue';

import AddCode from '@/components/dialogs/AddCode.vue';
import { mapGetters } from 'vuex';
import { DateTime } from 'luxon';

export default {
  components: { CreateEditUser, CreateEditOrganization, AddCode, ReadonlyCheckbox },
  name: 'Organization-Details',
  props: {
    OrgId: String,
  },
  data: () => ({
    userRole: {
      isInstructor: false,
    },
    currentUserPermission: [],
    showPermissionsDialog: false,
    organizations: [],
    showAddByEmailDialog: false,
    onlyStaff: false,
    currentOrganization: {},
    currentTab: 0,
    updatingState: false,
    codes: [],
    orgAccess: null,
  }),
  watch: {
    showAddByEmailDialog: {
      immediate: true,
      handler(val) {
        if (val) this.getOrganizations();
      },
    },
    OrgId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getOrganizationDetails();
        }
      },
    },
  },
  computed: {
    ...mapGetters(['isAdmin', 'currentOrgId', 'user']),
    users() {
      if (this.onlyStaff) {
        return this.currentOrganization.staff;
      } else {
        return this.currentOrganization.students;
      }
    },
  },
  methods: {
    async getOrganizationDetails() {
      this.currentOrganization = await apiService.getOrganizationById(this.OrgId);
      await apiService.getStaffOrganizationAccess(this.OrgId, this.user.id).then((result) => {
        this.orgAccess = this.$util.parseStaffAccessValue(result.organizationAccess, true);

        if (this.orgAccess.includes('ModifyStudents')) {
          this.getCodes();
        }
      });
    },
    async getCodes() {
      await apiService.getCompanyCodes(this.OrgId).then((result) => {
        result.forEach((code) => {
          code.expiryDate = DateTime.fromISO(code.expiryDate).toFormat('dd-MM HH:mm');
        });

        result = result.sort(
          (a, b) => DateTime.fromFormat(b.expiryDate, 'dd-MM HH:mm').toMillis() - DateTime.fromFormat(a.expiryDate, 'dd-MM HH:mm').toMillis()
        );

        this.codes = result;
      });
    },
    addStaff() {
      this.$refs.CreateEditUserRef.createStaff();
    },
    addStudent() {
      this.$refs.CreateEditUserRef.createUser();
    },
    editOrganization() {
      this.$refs.CreateEditOrganizationRef.editOrganization(this.currentOrganization);
    },
    async getOrganizations() {
      this.organizations = await apiService.getOrganizationList();
    },
    async deleteOrganization() {
      let dialogResponse = await this.$refs.confirmationDialogRef.showDialog({
        title: 'Slet køreskole',
        studentName: this.currentOrganization.name,
        body: `Ønsker du at slette denne køreskole?`,
        okBtn: 'Slet',
      });
      if (dialogResponse) {
        await apiService.deleteOrganization(this.currentOrganization.id);
        this.$router.push({ name: 'org' });
      }
      this.$refs.confirmationDialogRef.hideDialog();
    },
    userDetails(user) {
      if (user.roles != undefined) {
        this.isStaff = true;
        this.$router.push({ name: 'StaffDetails', params: { UserId: user.id } });
      } else {
        this.isStaff = false;
        this.$router.push({ name: 'StudentDetails', params: { UserId: user.id } });
      }
    },
    createCode() {
      this.$refs.AddCodeRef.addCodeToOrganization();
    },
    handleCode(code) {
      this.showSnackBar(
        `Kode oprettet`,
        `Udløber: ${DateTime.fromISO(code.expiryDate).toFormat('dd-MM HH:mm')} <br/>
                   Kode: ${code.code} <br/>`,
        'success'
      );
      code.expiryDate = DateTime.fromISO(code.expiryDate).toFormat('dd-MM HH:mm');
      this.codes.unshift(code);
      this.currentTab = 2;
    },
    showSnackBar(title, body, color) {
      this.$store.commit('alert', {
        show: true,
        title: title,
        color: color,
        textColor: 'white',
        message: body,
      });
    },
  },
  created() {
    this.currentOrganization = {};
  },
};
</script>

<style lang="scss">
.NoHover tr:hover,
.NoHover:before {
  background-color: transparent !important;
}
</style>
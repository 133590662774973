<template>
  <div>
    <v-navigation-drawer v-if="theoryTeamId == null" stateless temporary fixed width="40vw" color="drawerBackground" v-model="show" right>
      <v-card tile :light="!darkMode" :dark="darkMode" style="height: 100vh; width: 40vw">
        <v-card-title class="pl-4">
          <v-row no-gutters>
            <h3>{{ theoryTeamId == null ? 'Opret kode' : `Opret kode til ${theoryTeamName}` }}</h3>
            <r-btn class="ml-auto" icon @click="close">
              <v-icon dark>mdi-close-thick</v-icon>
            </r-btn>
          </v-row>
        </v-card-title>
        <v-card-text class="pl-6">
          <v-form ref="addCodeRef">
            <v-row no-gutters class="mb-4">
              <v-col cols="12" md="4" align-self="center">Lektionsplan:</v-col>
              <v-col cols="12" md="8" v-if="modulePlanList.length > 0">
                <v-select
                  :items="modulePlanList"
                  outlined
                  dense
                  hide-details=""
                  v-model="selectedModule"
                  item-value="id"
                  item-text="name"
                  placeholder="Lektionsplan"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-row class="pl-4" no-gutters>
            <r-btn :block="$vuetify.breakpoint.mobile" class="mr-lg-4 mb-2" @click="close">Annuller</r-btn>
            <r-btn :block="$vuetify.breakpoint.mobile" creation :loading="isSaving" :disabled="selectedModule == null" @click="CreateCode">
              <v-icon left>mdi-check</v-icon>
              Opret kode
            </r-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-navigation-drawer>

    <!-- <v-dialog v-else persistent v-model="show" width="650">
      <v-card>
        <v-row no-gutters>
          <v-col cols="12" class="d-flex">
            <v-card-title>{{ `Opret kode til ${theoryTeamName}` }}</v-card-title>
          </v-col>
          <v-row no-gutters class="justify-center px-2 pb-2">
            <v-col cols="6" class="px-2">
              <r-btn block @click="close"> Annuller </r-btn>
            </v-col>
            <v-col cols="6" class="px-2">
              <r-btn block creation @click="CreateCode"> Opret kode </r-btn>
            </v-col>
          </v-row>
        </v-row>
      </v-card>
    </v-dialog> -->
  </div>
</template>

<script>
import apiService from '@/services/apiService';
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    show: false,
    isSaving: false,
    isValid: false,
    modulePlanList: [],
    selectedModule: null,
    theoryTeamId: null,
    // theoryTeamName: null,
  }),
  computed: {
    ...mapGetters(['globalAlert', 'darkMode', 'currentOrgId']),
  },
  methods: {
    setActiveModulePlan(item) {
      this.selectedModule = item;
      this.isValid = true;
    },
    // addCodeToTheoryTeam(theoryTeam) {
    //   this.theoryTeamId = theoryTeam.id;
    //   this.theoryTeamName = theoryTeam.name;
    //   this.show = true;
    // },
    addCodeToOrganization() {
      this.getModuleLists();
      this.show = true;
    },
    async getModuleLists() {
      this.modulePlanList = await apiService.getModulePlanList(this.currentOrgId);
      if (this.modulePlanList.length == 1) {
        this.selectedModule = this.modulePlanList[0].id;
      }
    },
    async CreateCode() {
      let code;
      if (this.theoryTeamId == null) {
        code = await apiService.generateOrganizationCode(this.selectedModule);
        this.$emit('code-result', code);
      }
      // else {
      //   code = await apiService.getGenerateTheoryTeamCode(this.theoryTeamId);
      //   this.$emit('code-result', code);
      // }
      this.show = false;
    },
    showSnackBar(title, body, color) {
      this.$store.commit('alert', {
        show: true,
        title: title,
        color: color,
        textColor: 'white',
        message: body,
      });
    },

    close() {
      this.selectedModule = null;
      this.show = false;
    },
  },
};
</script>
